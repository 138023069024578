import { getCurrentStage, STAGE } from 'src/constant/StageConfig';

if (getCurrentStage().toLowerCase() === STAGE.PROD) {
  (window as any).FAB_URL = 'https://atoz-livehelp-app.amazon.work';
} else {
  (window as any).FAB_URL = 'https://atoz-livehelp-app.integ.amazon.work';
}

import('./bootstrap');
export {};
