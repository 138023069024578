/**
 * Stage name constants
 */
export enum STAGE {
  TEST = 'test',
  ALPHA = 'alpha',
  BETA = 'beta',
  PROD = 'prod',
}

export const getCurrentStage = (): STAGE => {
  return 'beta' as STAGE;
};
